import routes from '@config/routes';
import instance from '@instance';
import { LoggedInUserProps } from '@interfaces/auth';
import { Favourite } from '@interfaces/events';
import { SignInProps, UserExtendedProps } from '@interfaces/user';
import { getAffiliateData } from '@requests/get-pages-content/affiliates';
import { getAgencyMe } from '@requests/get-pages-content/agency';
import isValidToken from '@utils/auth/check-token';
import { USER_ROLE } from '@utils/auth/roles';
import isDevMode from '@utils/dev-mode';
import { v2Links } from '@utils/navigation/links';
import NextAuth, { AuthOptions, DefaultSession } from 'next-auth';
import CredentialsProvider from 'next-auth/providers/credentials';
import GoogleProvider from 'next-auth/providers/google';

declare module 'next-auth' {
  interface Session extends DefaultSession {
    user: {} & DefaultSession['user'] &
      LoggedInUserProps & {
        affiliationId?: string;
      };
  }

  // interface User {
  //   // ...other properties
  //   // role: UserRole;
  // }
}

export const authConfig: AuthOptions = {
  providers: [
    CredentialsProvider({
      name: 'credentials',
      //A.S: Credentials is a required property with a default signIn page but not used
      credentials: {
        email: { label: 'Email', type: 'text' },
        password: { label: 'Password', type: 'password' },
        rememberMe: { label: 'Remember Me', type: 'checkbox' },
        userType: { label: 'User type', type: 'text' },
      },
      async authorize(credentials) {
        const data = {
          email: credentials?.email,
          password: credentials?.password,
        };

        const endpoint =
          credentials?.userType === 'agency'
            ? routes.agencyLogin
            : routes.login;
        const loginResponse = await instance.server(endpoint, {
          body: JSON.stringify(data),
        });
        const response = await loginResponse.json();

        console.log({ response });

        if (loginResponse.ok) {
          return response;
        }

        if (response.message) {
          return { error: response.message };
        }

        return null;
      },
    }),
    GoogleProvider({
      clientId: process.env.GOOGLE_CLIENT_ID as string,
      clientSecret: process.env.GOOGLE_CLIENT_SECRET as string,
    }),

    {
      id: 'EST',
      name: 'EST',
      type: 'credentials',
      credentials: { token: { label: 'token', type: 'text' } },
      async authorize(credentials) {
        const data = {
          refresh_token: credentials?.token,
        };
        const response = Promise.all([
          instance.server(routes.user, {
            body: JSON.stringify(data),
          }),
          instance.server(routes.refresh, {
            body: JSON.stringify(data),
          }),
        ]);

        const [getUser, getToken] = await response;
        const user = await getUser.json();
        const token = await getToken.json();

        return {
          ...user,
          token,
        };
      },
    },
  ],
  callbacks: {
    async signIn({ user }: { user: UserExtendedProps }) {
      if (user?.error) {
        throw new Error(user.error);
      }

      return true;
    },
    async jwt({
      token,
      user,
      account,
      profile,
      trigger,
      session,
    }: SignInProps) {
      if (account?.provider === 'google' && profile?.email_verified) {
        //A.S: Code section not used due to EST provider workaround, keeping it for future cleanup
        const data = {
          email: profile?.email,
          firstName: profile?.given_name as string,
          lastName: profile?.family_name as string,
          authSource: account?.provider,
        };

        const loginResponse = await instance.server(routes.socialLogin, {
          body: JSON.stringify(data),
        });
        const response = await loginResponse.json();
        token = { ...response };
      } else if (trigger === 'update' && session) {
        token = { ...token, ...session };
      } else if (user) {
        token = { ...user };
      }

      const hasValidToken = isValidToken(token?.token?.accessToken);

      if (!hasValidToken) {
        const data = {
          refresh_token: token?.token?.refreshToken,
        };
        const response = await instance.server(routes.refresh, {
          body: JSON.stringify(data),
        });

        const newToken = await response.json();
        token.token = { ...newToken };
      }

      const follow = await instance.server(routes.favorite, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token?.token?.accessToken}`,
        },
      });

      if (follow.ok) {
        let favorites = await follow.json();

        favorites = favorites.map(
          ({ favoriteId: id, name, type }: Favourite) => ({
            id,
            name,
            type: type.slice(0, 1),
          })
        );
        token.follow = [...favorites];
      }

      if ([USER_ROLE.agency, USER_ROLE.agencyMember].includes(token.role)) {
        const agency = await getAgencyMe(token?.token?.accessToken);

        token = {
          ...token,
          affiliationId: agency?.affiliationId,
        };
      }

      if (
        [USER_ROLE.affiliate, USER_ROLE.individualAgent].includes(token.role)
      ) {
        const affiliateData = await getAffiliateData(token?.token?.accessToken);

        token = {
          ...token,
          affiliationId: affiliateData?.affiliationId,
        };
      }

      return token;
    },
    async session({ session, token }) {
      if (session?.user) {
        session.user = { ...token };
      }

      return session;
    },
  },
  secret: process.env.NEXTAUTH_SECRET,
  session: {
    strategy: 'jwt',
  },
  pages: {
    signIn: v2Links.login,
  },
  debug: isDevMode,
};

export default NextAuth(authConfig);
