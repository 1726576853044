import { useBetterMediaQuery } from '@hooks/use-ssr-media-query';
import { useCheckoutStepsStore } from '@store/checkout-steps-store';
import {
  Building05,
  Check,
  CreditCard02,
  Divider,
  SVGComponentProps,
  Ticket01,
} from '@v2/ui';
import { cn } from '@v2/utils';
import { usePathname } from 'next/navigation';
import { FC } from 'react';

export function BuildPackageSteps() {
  const pathname = usePathname();

  const isSearchTicketsPage = pathname?.includes('/search-tickets') ?? false;
  const isSearchHotelPage = pathname?.includes('/search-hotel') ?? false;

  const isTicketConfirmed = useCheckoutStepsStore(
    (state) => state.ticketSelected
  );
  const isHotelConfirmed = useCheckoutStepsStore(
    (state) => state.hotelSelected
  );

  return (
    <div className="flex items-center">
      <PillStep
        title="Tickets"
        icon={Ticket01}
        isActive={isSearchTicketsPage}
        isSelected={isTicketConfirmed}
      />
      <Divider className="w-2 md:w-3" />
      <PillStep
        title="Hotel"
        icon={Building05}
        isActive={isSearchHotelPage}
        isSelected={isHotelConfirmed}
      />
      <Divider className="w-2 md:w-3" />
      <PillStep
        title="Checkout"
        icon={CreditCard02}
        isActive={false}
        isSelected={false}
      />
    </div>
  );
}

type PillStep = {
  title: string;
  icon: FC<SVGComponentProps>;
  isActive: boolean;
  isSelected: boolean;
};
function PillStep({ title, icon, isActive, isSelected }: PillStep) {
  const Icon = icon;

  const isTablet = useBetterMediaQuery('(min-width: 768px)');


  return (
    <div
      className={cn(
        `flex items-center justify-center gap-x-3 rounded-full border border-gray-300 px-3 py-[7px] md:px-4 md:py-2 size-8 md:size-auto`,
        isActive && 'border-gray-800 bg-gray-800 size-auto',
        isSelected && 'border-success-600 bg-transparent text-success-600'
      )}
    >
      {isSelected ? (
        <Check
          size={isTablet ? '20' : '16'}
          pathProps={{ stroke: '#079455' }}
          className='flex-shrink-0'


        />
      ) : (
        <Icon
          size={isTablet ? '20' : '16'}
          pathProps={{ stroke: isActive ? 'white' : '#667085' }}
          className='flex-shrink-0'

        />
      )}

      {(isTablet || isActive) && (
        <span
          className={cn(
            'md:text-md text-xs font-semibold text-gray-500',
            isActive && 'text-white',
            isSelected && 'text-success-600'
          )}
        >
          {title}
        </span>
      )}

    </div>
  );
}
