'use client';
import {
  Divider,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@v2/ui';
import { adminRoles, cn } from '@v2/utils';
import { useSession } from 'next-auth/react';
import { useState } from 'react';

type Props = {
  orderDetails: {
    comission: number | string;
    ticketCommission: number;
    hotelCommission: number;
    currency: string;
  };
  children: React.ReactNode;
};

export function TooltipComission({ orderDetails, children }: Props) {
  const { data: sessionData } = useSession();
  const userRole = sessionData?.user.role;

  const [isOpen, setIsOpen] = useState(false);
  const isAdmin = adminRoles.includes(userRole);

  if (!isAdmin || (isAdmin && !orderDetails.comission)) return children;

  const hasSplitComission =
    orderDetails?.ticketCommission && orderDetails?.hotelCommission;

  return (
    <TooltipProvider>
      <Tooltip
        delayDuration={100}
        open={false}
        onOpenChange={(val) => {
          console.log(val);
        }}
      >
        <TooltipTrigger
          onClick={(event) => event.preventDefault()}
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
          className={cn(
            'border-b border-dashed',
            isOpen ? 'border-primary-500' : 'border-gray-200'
          )}
        >
          {children}
        </TooltipTrigger>

        <TooltipContent
          className={cn(
            'gap-Y-2 flex min-w-[200px] flex-col items-center px-3 py-2'
          )}
        >
          {hasSplitComission ? (
            <>
              <ComissionLabel
                label="Ticket comission"
                value={` ${orderDetails?.currency} ${Number(orderDetails?.ticketCommission).toFixed(2)} `}
              />

              <Divider className="my-2" />

              <ComissionLabel
                label="Hotel comission"
                value={` ${orderDetails?.currency} ${Number(orderDetails?.hotelCommission).toFixed(2)} `}
              />
            </>
          ) : (
            <>
              <ComissionLabel
                label="Total comission"
                value={` ${orderDetails?.currency} ${orderDetails?.comission} `}
              />
            </>
          )}
          <TooltipArrow />
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

function ComissionLabel({
  label,
  value,
}: {
  label: string;
  value: string | number;
}) {
  return (
    <>
      <div className="flex items-center justify-between gap-x-[30px]">
        <h4 className="text-xs font-semibold text-gray-500">{label}</h4>
        <p className="text-xs font-semibold text-gray-700">{value}</p>
      </div>
    </>
  );
}
