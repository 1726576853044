import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface UIState {
  activeStep: 'tickets' | 'hotel' | 'flights';
  cartId: string;
  setCartId: (cartId: string) => void;
  ticketSelected: boolean;
  hotelSelected: boolean;
  clearAllState: () => void;
}

export const useCheckoutStepsStore = create(
  persist<UIState>(
    (set) => ({
      cartId: '',
      setCartId: (cartId) => set({ cartId }),
      activeStep: 'tickets',
      ticketSelected: false,
      hotelSelected: false,
      clearAllState: () => {
        set({
          activeStep: 'tickets',
          ticketSelected: false,
          hotelSelected: false,
        });
      },
    }),
    {
      name: 'stps',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
