import { PACKAGE_STORAGE } from '@config/storageKeys';
import { IFormValues } from '@interfaces/buildPackage';
import { getHotelGuestsCount } from '@utils/filterForHotel';
import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import useEventLoader from './use-event-loader';
import useMinimumPrice from './useMinimumPrice';
import useStorage from './useStorage';

type PackageDataType = IFormValues | null;



export function useBuildPackageBundle() {
  const storage = useStorage();

  const [packageGuests, setPackageGuests] = useState<number>(0);

  const [currentPackageData] = useState<PackageDataType>(
    JSON.parse(storage.getItem(PACKAGE_STORAGE) ?? '{}')
  );


  const isBundle = currentPackageData?.selected?.tickets;

  const searchParams = useSearchParams();
  const eventId = searchParams?.get('eventId');
  const { loading, tickets } = useEventLoader(eventId, isBundle);

  const { ticketPrice } = useMinimumPrice(
    [],
    tickets,
    currentPackageData?.tickets
  );

  useEffect(() => {
    const hotelGuests = getHotelGuestsCount(
      currentPackageData?.roomConfigurations ?? []
    );
    const totalTickets = currentPackageData?.tickets ?? 0;
    setPackageGuests(hotelGuests >= totalTickets ? hotelGuests : totalTickets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPackageData]);

  return {
    isBundle,
    packageGuests,
    packageData: currentPackageData,
    minTicketPrice: ticketPrice,
  };
}
