import type { TicketGroup } from '@interfaces/ticketevolution/tikets';
import { create } from 'zustand';

export type TicketSortBy = 'lowestPrice' | 'bestSeats';

type TicketsStore = {
  selectedGroups: string[];
  ticketsQuantity: number;
  maxPrice: number;
  isOnlyElectronic: boolean;
  includeFees: boolean;
  priceRange: number[]; // Changed type annotation here
  ticketSelected: TicketGroup | null;
  sortby: TicketSortBy;
  taxQuota: TaxQuota;
  isTicketSelectionConfirmed: boolean;
};

type TaxQuota = {
  tax: number;
  tax_signature: string | null;
};
type Actions = {
  setSelectedGroups: (val: string[]) => void;
  setTickets: (val: 'increment' | 'decrement') => void;
  setPriceRange: (val: number[]) => void;
  setSortBy: (val: TicketSortBy) => void;
  reset: () => void;
  setTicketSelectionConfirmed: ({
    isTicketSelectionConfirmed,
    taxQuota,
  }: {
    taxQuota: TaxQuota;
    isTicketSelectionConfirmed: boolean;
  }) => void;
  toggleBoolean: (
    val: keyof Pick<TicketsStore, 'isOnlyElectronic' | 'includeFees'>
  ) => void;

  setTicketSelected: (val: TicketGroup | null) => void;
};

const initState: TicketsStore = {
  selectedGroups: [],
  ticketsQuantity: 2,
  maxPrice: 100,
  sortby: 'lowestPrice',
  isOnlyElectronic: false,
  includeFees: false,
  priceRange: [0, 1000],
  ticketSelected: null,
  taxQuota: {
    tax: 0,
    tax_signature: null,
  },
  isTicketSelectionConfirmed: false,
};
export const useTicketsStore = create<TicketsStore & Actions>((set) => ({
  ...initState,
  setPriceRange: (val) => set({ priceRange: val }),
  setSortBy: (val) => set({ sortby: val }),
  setTicketSelectionConfirmed: (val) => set({ ...val }),
  setSelectedGroups: (selectedGroups) => set({ selectedGroups }),
  setTicketSelected: (ticketSelected) => set({ ticketSelected }),
  toggleBoolean: (key) => set((state) => ({ [key]: !state[key] })),
  setTickets: (val) => {
    set((state) => {
      if (val === 'increment') {
        return { ticketsQuantity: state.ticketsQuantity + 1 };
      } else if (val === 'decrement' && state.ticketsQuantity > 1) {
        return { ticketsQuantity: state.ticketsQuantity - 1 };
      }
      return state;
    });
  },
  reset: () => {
    set(initState);
  },
}));
