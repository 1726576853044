'use client';
import { LeagueEvent } from '@interfaces/APITravel';
import { v2Links } from '@utils/navigation/links';
import { CurrencyDropdown } from '@v2/containers/CurrencyPicker/CurrencyDropdown';
import {
  Button,
  Close,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Skeleton,
  typographyVariants,
} from '@v2/ui';
import { cn } from '@v2/utils';
import moment from 'moment-timezone';
import { usePathname, useRouter } from 'next/navigation';
import { PropsWithChildren, useState } from 'react';
import { useIsClient, useMediaQuery } from 'usehooks-ts';

import { useSearchHotelStore } from '@store/search-hotel-store';
import { useTicketsStore } from '@store/tickets-store';
import HotelMapFilterDialog from '@v2/views/SearchHotel/components/HotelContent/HotelMapFilterDialog';
import { BuildPackageSteps } from './BuildPackageSteps';
import { useCheckoutStepsStore } from '@store/checkout-steps-store';

type Props = {
  event: LeagueEvent;
} & PropsWithChildren;

export function BuyPackageHeader({ event, children }: Props) {
  const isClient = useIsClient();
  const date = moment(event.occurs_at_local)
    .tz('America/New_York')
    .format('MMM D, YYYY');

  const matches = useMediaQuery('(min-width: 1024px)');
  const pathname = usePathname();
  const isTicketsAndHotel =
    pathname?.includes('/search-tickets') ||
    pathname?.includes('/search-hotel');

  const isMapFullScreen = useSearchHotelStore((state) => state.isMapFullScreen);

  if (!event) return null;
  const time = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true, // For 12-hour time format
    timeZone: 'America/New_York',
  }).format(new Date(event.occurs_at_local));

  if (!isClient) return <Skeleton className="h-[96px] w-full" />;

  return (
    <header className="relative z-[30] border-b border-gray-200 bg-white">
      <div className="flex w-full flex-col-reverse items-start justify-between gap-y-5 p-4 md:mx-auto md:max-w-[1400px] md:flex-row md:px-20 md:py-6">
        <div className="flex items-center gap-x-4">
          {isMapFullScreen && (
            <>
              {matches && (
                <Button
                  className="h-12 w-12 rounded-full px-0"
                  variant="secondary"
                  onClick={() => {
                    useSearchHotelStore.setState({ isMapFullScreen: false });
                  }}
                >
                  <Close className="text-gray-800" size="20" />
                </Button>
              )}
              {!matches && (
                <button
                  onClick={() => {
                    useSearchHotelStore.setState({ isMapFullScreen: false });
                  }}
                >
                  <Close className="text-gray-800" size="20" />
                </button>
              )}
            </>
          )}
          <div>
            <h5 className="md:text-md mb-1 max-w-[46ch] truncate text-xs font-bold text-gray-800 lg:max-w-[50ch]">
              {event.name}
            </h5>
            <p
              className="text-[10px] font-medium text-gray-500 lg:text-sm"
              suppressHydrationWarning
            >
              {date} • {time}
              {matches && ` • ${event.venue.name}, ${event.venue.location}`}
              {!matches && ` • ${event.venue.location}`}
            </p>
          </div>
        </div>
        <div
          className={cn(
            'flex w-full items-center justify-between gap-x-5 md:w-auto md:justify-normal md:gap-x-6',
            !isTicketsAndHotel && 'justify-end'
          )}
        >
          {!isTicketsAndHotel ? (
            <div className="block flex-shrink-0">
              <CurrencyDropdown variant={'black'} className="mr-0 px-0 py-0" />
            </div>
          ) : (
            <BuildPackageSteps />
          )}

          {!isMapFullScreen && (
            <>
              {matches && children}
              <LeavePageModal />
            </>
          )}
          {isMapFullScreen && <HotelMapFilterDialog />}
        </div>
      </div>
    </header>
  );
}

function LeavePageModal() {
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const pathname = usePathname();
  const matches = useMediaQuery('(min-width: 1024px)');

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          variant="secondary"
          size={'xl'}
          className={cn(
            'h-8 w-8 rounded-full p-2 md:h-auto md:w-auto md:p-[14px]'
          )}
        >
          <Close pathProps={{ stroke: '#1D2939' }} />
        </Button>
      </DialogTrigger>
      <DialogContent className="gap-10">
        <DialogHeader>
          <DialogTitle
            className={cn(
              typographyVariants({ size: 'dsm' }),
              'text-center font-bold'
            )}
          >
            Leave page?
          </DialogTitle>
        </DialogHeader>

        <DialogFooter className="justify-star md:gap-y0 w-full gap-y-3">
          <Button
            className="w-full"
            variant="secondary"
            onClick={() => {
              setOpen(false);
              router.push(v2Links.home);
              useTicketsStore.getState().reset();
              useSearchHotelStore.getState().reset();
              useCheckoutStepsStore.getState().clearAllState();
            }}
          >
            Leave without saving
          </Button>
          <Button className="w-full" onClick={() => setOpen(false)}>
            Stay here
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
