import type { RoomConfiguration } from '@components/RoomConfigurator';
import type { ILocationResult } from '@components/searchCity';
import type { Performer } from '@interfaces/buildPackage';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export interface BuildPackageState {
  _hasHydrated: boolean;
  tickets: number;
  city?: string;
  location?: ILocationResult | undefined | null;
  isBundle: boolean;
  hotelStartDate: string;
  hotelEndDate: string;
  roomConfigurations: RoomConfiguration[];
  eventDate?: string;
  eventName?: string;
  timeZone?: string;
  setHasHydrated: (state: boolean) => void;

  performers?: Array<Performer>;
  selectedTicketPrice: number;
}

const initValues = {
  selectedTicketPrice: 0,
  _hasHydrated: false,
  isBundle: false,
  tickets: 0,
  location: null,
  hotelStartDate: '',
  hotelEndDate: '',
  roomConfigurations: [],
  eventDate: '',
  eventName: '',
  timeZone: '',
  performers: [],
};

export const useBuildPackagesStore = create(
  persist<BuildPackageState>(
    (set, get) => ({
      ...initValues,
      setHasHydrated: (state) => {
        set({
          _hasHydrated: state,
        });
      },
    }),
    {
      name: 'build-package-store',
      storage: createJSONStorage(() => localStorage),

      onRehydrateStorage: () => (state) => {
        state?.setHasHydrated(true);
      },
    }
  )
);
