import { AlertToast, AlertTriangle } from '@v2/ui';
import { toast, ToastOptions } from 'react-hot-toast';

const options: ToastOptions = {
  position: 'top-right',
};

export const errorToast = (message: string) => {
  toast.error(message, options);
};

export const successToast = (message: string) => {
  toast.success(message, options);
};

export const errorToastV2 = ({
  title = 'Oops, something went wrong',
  message = 'Please try again in a minute.',
}: {
  title?: string;
  message?: string;
}) => {
  return toast.custom(
    (t) => (
      <AlertToast
        {...t}
        title={title}
        content={message}
        icon={<AlertTriangle />}
      />
    ),
    { position: 'top-right' }
  );
};
